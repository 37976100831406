import { ROUTES } from '@/libs/routing';

export const META_KEYS = {
  HOME: 'homepage',
  OUR_DATA: 'ourData',
  MARKETING_SOLUTIONS: 'marketingSolutions',
  SALES_SOLUTIONS: 'salesSolutions',
  RECRUITING_SOLUTIONS: 'recruitingSolutions',
  PLANS_AND_PRICING: 'plansAndPricing',
  CONTACT_US: 'contactUs',
  REQUEST_DEMO: 'requestDemo',
  PRIVACY_CENTER: 'privacyCenter',
  OPT_OUT: 'optOut',
  WHY_POWERLEAD: 'whyPowerlead',
  REQUEST_ACCESS: 'requestAccess',
  ABOUT: 'aboutPowerlead',
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY_POLICY: 'privacyPolicy',
  COOKIES_POLICY: 'cookiesPolicy',
  SUCCESS_STORIES: 'successStories',
  SUCCESS_STORY_TALENTEDGE: 'successStoryTalentedge',
  SUCCESS_STORY_LGFG: 'successStoryLgfg',
  SUCCESS_STORY_RANGETELLER: 'successStoryRangeteller',
  SUCCESS_STORY_MOBLERS: 'successStoryMoblers',
};

export const MetaKeytoRouteDictionary = {
  [ROUTES.HOME]: META_KEYS.HOME,
  [ROUTES.PRICING]: META_KEYS.PLANS_AND_PRICING,
  [ROUTES.WHY_POWERLEAD]: META_KEYS.WHY_POWERLEAD,
  [ROUTES.OUR_DATA]: META_KEYS.OUR_DATA,
  [ROUTES.MARKETING_SOLUTIONS]: META_KEYS.MARKETING_SOLUTIONS,
  [ROUTES.DEMO]: META_KEYS.REQUEST_DEMO,
  [ROUTES.ABOUT]: META_KEYS.ABOUT,
  [ROUTES.CONTACT_US]: META_KEYS.CONTACT_US,
  [ROUTES.SALES_SOLUTIONS]: META_KEYS.SALES_SOLUTIONS,
  [ROUTES.RECRUITING_SOLUTIONS]: META_KEYS.RECRUITING_SOLUTIONS,
  [ROUTES.PRIVACY_CENTER]: META_KEYS.PRIVACY_CENTER,
  [ROUTES.OPT_OUT]: META_KEYS.OPT_OUT,
  [ROUTES.PRIVACY_POLICY]: META_KEYS.PRIVACY_POLICY,
  [ROUTES.TERMS_OF_USE]: META_KEYS.TERMS_OF_USE,
  [ROUTES.REQEST_ACCESS]: META_KEYS.REQUEST_ACCESS,
  [ROUTES.SUCCESS_STORIES]: META_KEYS.SUCCESS_STORIES,
  [ROUTES.SUCCESS_STORY('talentedge')]: META_KEYS.SUCCESS_STORY_TALENTEDGE,
  [ROUTES.SUCCESS_STORY('LGFG')]: META_KEYS.SUCCESS_STORY_LGFG,
  [ROUTES.SUCCESS_STORY('RangeTeller')]: META_KEYS.SUCCESS_STORY_RANGETELLER,
  [ROUTES.SUCCESS_STORY('moblers')]: META_KEYS.SUCCESS_STORY_MOBLERS,
};
