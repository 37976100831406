import { FC } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Container } from '@/components';
import { sofiaPro, playfairDisplay } from '@/libs/fonts';
import bg from './assets/bg.webp';
import styles from './styles.module.scss';

export const Headline: FC = () => {
  const { t } = useTranslation('successStories');

  return (
    <Container className={styles.container}>
      <Box className={styles.body}>
        <h1 className={clsx(styles.title, playfairDisplay.className)}>
          {t('headline.title')}
        </h1>
        <h6 style={sofiaPro.style} className={styles.subtitle}>
          {t('headline.description')}
        </h6>
        <Box className={styles.bg}>
          <Image src={bg} alt="bg" layout="fill" objectFit="cover" />
        </Box>
      </Box>
    </Container>
  );
};
