import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import { Container } from '@/components';
import Link from 'next/link';
import { East } from '@mui/icons-material';
import { EXTERNAL_ROUTES, ROUTES } from '@/libs/routing';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { HeadVideo } from './components';

export const HeadSection: React.FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'head' });

  return (
    <Box component="section" className={styles.section}>
      <Container>
        <Typography className={styles.title} variant="h1">
          {t('title')}
        </Typography>
        <Typography className={styles.subtitle}>{t('subtitle')}</Typography>
        <Box className={styles.buttons}>
          <Button
            variant="contained"
            color="secondary"
            href={EXTERNAL_ROUTES.DASHBOARD_SIGN_UP}
            target="_blank"
          >
            {t('btnTryFree')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.talkToUs}
            href={ROUTES.DEMO}
          >
            {t('talkToUs')}
            <Image src="/images/home/talkToUsButton.webp" alt="" width={84} height={23} />
          </Button>
        </Box>
        <Typography className={styles.getStarted}>
          {t('notion')}{' '}
          <Link href={ROUTES.PRICING}>
            {t('seePricing')}
            {/* @ts-ignore */}
            <East fontSize="10px" />
          </Link>
        </Typography>
        <Box className={styles.videoArrow}>
          <Image src="/images/home/videoArrow.webp" alt="" width={200} height={63} />
        </Box>
        <HeadVideo />
      </Container>
    </Box>
  );
};
