import { FC, Fragment, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { Stats, StatsCarouselProps } from './components';
import { IStatEntity } from '@/features/home/domain';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { ArrowLink } from '@/components';
import { ROUTES } from '@/libs/routing';

const StatsCarousel = dynamic<StatsCarouselProps>(
  () => import('./components').then((module) => module.StatsCarousel),
  {
    ssr: false,
  },
);

const stats: IStatEntity[] = [
  {
    id: 1,
    title: {
      text: '120',
      symbol: 'm',
    },
    description: 'stats.1',
  },

  {
    id: 2,
    title: {
      text: '3',
      symbol: 'x',
    },
    description: 'stats.2',
  },
  {
    id: 3,
    title: {
      text: '400',
      symbol: '%',
    },
    description: 'stats.3',
  },
  {
    id: 4,
    title: {
      text: '156',
      symbol: '%',
    },
    description: 'stats.4',
  },
  {
    id: 5,
    title: {
      text: '99',
      symbol: '%',
    },
    description: 'stats.5',
  },
];

export const HittingNumberSection: FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'hittingNumbers' });

  const translatedStats = useMemo(() => {
    return stats.map((stat) => ({
      ...stat,
      description: t(stat.description),
    }));
  }, [t]);

  return (
    <Fragment>
      <Box component="section" className={clsx(styles.section, styles.desktopOnly)}>
        <Typography variant="h2" className={styles.title}>
          {t('title')}
        </Typography>
        <Stats stats={translatedStats} />
        <ArrowLink variant="black" text={t('pricingLink')} href={ROUTES.PRICING} />
      </Box>
      <Box component="section" className={clsx(styles.section, styles.mobileOnly)}>
        <Typography variant="h2" className={styles.title}>
          {t('title')}
        </Typography>
        <Box className={styles.stats}>
          <Typography variant="h3" className={styles.statsTitle}>
            {t('subtitle')}
          </Typography>
          <StatsCarousel stats={translatedStats} />
        </Box>
      </Box>
    </Fragment>
  );
};
