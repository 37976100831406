import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { sofiaPro } from '@/libs/fonts';
import { Breadcrumbs, Container, ExtensionPromoSection } from '@/components';
import { Headline, Catalog } from './components';
import styles from './styles.module.scss';
import { StoryCard } from './types';
import { ROUTES } from '@/libs/routing';

type SuccessStoriesProps = {
  items: StoryCard[];
};

export const SuccessStories: FC<SuccessStoriesProps> = ({ items }) => {
  const { t } = useTranslation('successStories');

  return (
    <Box className={clsx(styles.wrapper, sofiaPro.className)}>
      <Breadcrumbs
        items={[
          {
            label: t('header.nav.successStories.title', { ns: 'common' }),
            url: ROUTES.SUCCESS_STORIES,
          },
        ]}
      />
      <Box className={styles.headline}>
        <Headline />
      </Box>
      <Box className={styles.catalog}>
        <Container>
          <Catalog items={items} />
        </Container>
      </Box>
      <ExtensionPromoSection />
    </Box>
  );
};
