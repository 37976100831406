import { Home } from '@/features/home';
import type { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { MetaData } from '@/components/MetaData';

const IndexPage: NextPage = () => {
  return (
    <>
      <MetaData />
      <Home />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations('en', [
        'common',
        'metaData',
        'home',
        'successStories',
      ])),
    },
  };
};

export default IndexPage;
