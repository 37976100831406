import { FC } from 'react';
import Link from 'next/link';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation, Trans } from 'next-i18next';
import Image from 'next/image';
import clsx from 'clsx';

import { sofiaPro } from '@/libs/fonts';

import { StoryCard } from '../../../../types';

import styles from './styles.module.scss';
import { ROUTES } from '@/libs/routing';
import { WithGradientedName } from '@/components';

export const Card: FC<StoryCard> = ({ title, description, image, slug }) => {
  const { t } = useTranslation('successStories');

  return (
    <Link className={styles.card} href={`${ROUTES.SUCCESS_STORIES}/${slug}`}>
      <Box className={styles.image} style={{ backgroundColor: image.color }}>
        <Image {...image} alt={title} />
      </Box>
      <Box className={styles.content}>
        <Typography variant="h4" className={clsx(styles.title, sofiaPro.className)}>
          <WithGradientedName text={title} />
        </Typography>
        <Typography
          variant="subtitle1"
          className={clsx(styles.description, sofiaPro.className)}
        >
          <Trans
            defaults={description}
            components={{
              br: <br />,
            }}
          />
        </Typography>
        <Button className={styles.link}>
          <span>{t('storyCard.readCase')}</span>
        </Button>
      </Box>
    </Link>
  );
};
