import { Box } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import styles from './styles.module.scss';
import { Pagination } from 'swiper/modules';
import { IStatEntity } from '@/features/home/domain';
import { StatSlide } from './StatSlide';

export type StatsCarouselProps = {
  stats: IStatEntity[];
};

export const StatsCarousel: FC<StatsCarouselProps> = ({ stats }) => {
  const swiperRef = useRef<SwiperRef>(null);

  const statChunks = useMemo(() => {
    return stats
      .reduce((acc, number, index) => {
        const chunkIndex = Math.floor(index / 2);
        const chunkArray = acc[chunkIndex] ?? [];
        chunkArray.push(number);
        acc[chunkIndex] = chunkArray;
        return acc;
      }, [] as IStatEntity[][])
      .map((chunk, index) => ({
        id: index,
        items: chunk,
      }));
  }, [stats]);

  return (
    <Box className={styles.root}>
      <Box className={styles.sliderWrapper}>
        <Swiper
          ref={swiperRef}
          pagination={{
            clickable: true,
            el: `.${styles.pagination}`,
            bulletActiveClass: styles.bulletActive,
            bulletClass: styles.bullet,
          }}
          navigation={false}
          modules={[Pagination]}
          autoplay={{ delay: 5000 }}
          loop
        >
          {statChunks.map((slide) => (
            <SwiperSlide key={slide.id}>
              <StatSlide key={slide.id} items={slide.items} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box className={styles.pagination}></Box>
    </Box>
  );
};
