import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';
import { Container, LazyLottie, SectionButtons } from '@/components';
import { Trans, useTranslation } from 'next-i18next';
import animationPreview from '/public/images/home/animationPreview4.webp';
import { ROUTES } from '@/libs/routing';
import Image from 'next/image';

export const AccurateDataSection: FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'accurateData' });

  return (
    <Box className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.image}>
          <LazyLottie
            dataSrc="/lotties/home/animation4.json"
            previewSrc={animationPreview}
            previewProps={{ alt: '', loading: 'lazy' }}
          />
        </Box>
        <Box className={styles.content}>
          <Typography className={styles.topTitle}>{t('title')}</Typography>
          <Typography variant="h3" className={styles.title}>
            {t('contentTitle')}
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.1" components={{ span: <span /> }} />
            <Image
              src="/images/home/accurateDataStepHighlight.webp"
              alt=""
              width={72}
              height={10}
            />
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.2" components={{ span: <span /> }} />
            <Image
              src="/images/home/accurateDataStepHighlight.webp"
              alt=""
              width={72}
              height={10}
            />
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.3" components={{ span: <span /> }} />
            <Image
              src="/images/home/accurateDataStepHighlight.webp"
              alt=""
              width={72}
              height={10}
            />
          </Typography>
          <SectionButtons learmMoreHref={ROUTES.OUR_DATA} />
        </Box>
      </Container>
    </Box>
  );
};
