import { FC } from 'react';
import { LazyLottie } from '@/components';
import { Box } from '@mui/material';
import animationPreview from '/public/images/home/animationPreview1.webp';
import styles from './styles.module.scss';

export const HeadImage: FC = () => {
  return (
    <Box className={styles.image}>
      <LazyLottie
        dataSrc="/lotties/home/animation1.json"
        previewSrc={animationPreview}
        previewProps={{ priority: true, alt: '' }}
      />
    </Box>
  );
};
