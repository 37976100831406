import { FC } from 'react';
import { Box } from '@mui/material';

import { Card } from './components';
import { StoryCard } from '../../types';

import styles from './styles.module.scss';

type CatalogProps = {
  items: StoryCard[];
};

export const Catalog: FC<CatalogProps> = ({ items }) => {
  return (
    <Box className={styles.body}>
      <Box className={styles.grid}>
        {items.map((item) => {
          return <Card key={item.slug} {...item} />;
        })}
      </Box>
    </Box>
  );
};
