import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '@/components';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import UnlimitedImage from './assets/unlimited.svg';
import SimpleImage from './assets/simple.svg';
import AccurateImage from './assets/accurate.svg';
import { FeaturesCarouselProps, Feature } from './components';
import styles from './styles.module.scss';
import { ROUTES } from '@/libs/routing';
import dynamic from 'next/dynamic';
import { WithGradientedName } from '@/components/WithGradientedName/WithGradientedName';

const FeaturesCarousel = dynamic<FeaturesCarouselProps<Feature>>(
  () => import('./components').then((module) => module.FeaturesCarousel),
  {
    ssr: false,
  },
);

type Feature = {
  id: number;
  title: string;
  description: string;
  image: ReactNode;
  link: {
    text: string;
    href: string;
  };
};

export const UniqueFeaturesSection: FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'uniqueFeatures' });

  const features = useMemo<Feature[]>(() => {
    return [
      {
        id: 1,
        title: t('features.0.title'),
        description: t('features.0.description'),
        image: <UnlimitedImage width="81px" />,
        link: {
          text: t('features.0.linkText'),
          href: ROUTES.PRICING,
        },
      },
      {
        id: 2,
        title: t('features.1.title'),
        description: t('features.1.description'),
        image: <SimpleImage width="52px" />,
        link: {
          text: t('features.1.linkText'),
          href: ROUTES.WHY_POWERLEAD,
        },
      },
      {
        id: 3,
        title: t('features.2.title'),
        description: t('features.2.description'),
        image: <AccurateImage width="80px" />,
        link: {
          text: t('features.2.linkText'),
          href: ROUTES.OUR_DATA,
        },
      },
    ];
  }, [t]);

  return (
    <Box component={'section'} className={styles.section}>
      <Container className={styles.container}>
        <Typography variant={'h2'} className={styles.title}>
          <WithGradientedName text={t('title')} />
        </Typography>
        <Box className={clsx(styles.content, styles.desktopOnly)}>
          {features.map(
            (feature): ReactElement => (
              <Feature key={feature.id} feature={feature} />
            ),
          )}
        </Box>
        <Box className={clsx(styles.content, styles.mobileOnly)}>
          <FeaturesCarousel
            items={features}
            renderSlide={(feature): ReactElement => (
              <Feature feature={feature} fullWidth />
            )}
          />
        </Box>
      </Container>
    </Box>
  );
};
