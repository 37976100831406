import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './styles.module.scss';
import { Pagination } from 'swiper/modules';
import { ReactNode, JSX } from 'react';
import clsx from 'clsx';

export type FeaturesCarouselProps<T> = {
  items: T[];
  renderSlide: (item: T) => JSX.Element;
  className?: string;
};

export const FeaturesCarousel = <T extends { id: string | number }>({
  items,
  renderSlide,
  className,
}: FeaturesCarouselProps<T>): ReactNode => {
  return (
    <Box className={clsx(styles.root, className)}>
      <Swiper
        pagination={{
          clickable: true,
          el: `.${styles.pagination}`,
          bulletActiveClass: styles.bulletActive,
          bulletClass: styles.bullet,
        }}
        navigation={false}
        modules={[Pagination]}
        autoplay={{ delay: 5000 }}
        loop
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>{renderSlide(item)}</SwiperSlide>
        ))}
      </Swiper>
      <Box className={styles.pagination}></Box>
    </Box>
  );
};
