import { Box } from '@mui/material';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

export const HeadVideo: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const options = {
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          void videoElement?.play();
          setIsVideoPlaying(true);
        }
      });
    }, options);

    if (videoElement) {
      observer.observe(videoElement);
    }

    return (): void => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  const toggleVideoPlay = (): void => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        void videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  return (
    <Box className={styles.wrapper}>
      <video
        ref={videoRef}
        src="/videos/promo.webm"
        width="100%"
        height="100%"
        poster="/images/home/videoPlaceholder.webp"
        controls={isVideoPlaying}
        muted
        onPlaying={() => setIsVideoPlaying(true)}
        playsInline
      />
      {!isVideoPlaying && (
        <Box className={styles.playButton} onClick={toggleVideoPlay}>
          <Image src="/images/home/videoPlayButton.webp" alt="" width={70} height={70} />
        </Box>
      )}
    </Box>
  );
};
